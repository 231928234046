import * as React from "react";
import { PropertyHostProfile as SharedUiPropertyHostProfile } from "@shared-ui/lodging-property-details";
import { RefTarget } from "@shared-ui/ref-context";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ComponentProps } from "typings/flexFramework/FlexComponent";
import { UitkSpacing } from "@egds/react-core/spacing";
import { LazyLoad } from "src/components/utility/LazyLoad/LazyLoad";

const WrappedPropertyHostProfile = PropertyBexApiWrapper(SharedUiPropertyHostProfile);

export const PropertyHostProfileLazyLoad: React.FC<ComponentProps> = ({ context }) => (
  <UitkSpacing margin={{ block: "three" }}>
    <RefTarget name="Host" tag="section">
      <LazyLoad context={context}>
        <WrappedPropertyHostProfile context={context} />
      </LazyLoad>
    </RefTarget>
  </UitkSpacing>
);

export default PropertyHostProfileLazyLoad;
